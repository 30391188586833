<template>
  <!-- 新增充值页面 -->
  <div class="makeOutInvoice page-info-content">
    <el-form
      ref="ruleForm"
      :model="submitObj"
      :rules="rules"
      :inline="true"
      label-width="120px"
      class="page-form form-item-row"
      label-position="left"
    >
      <el-form-item label="发票代码：" prop="invoiceCode">
        <el-input v-model="submitObj.invoiceCode" placeholder="请输入发票代码" />
      </el-form-item>
      <el-form-item label="发票号码：" prop="invoiceNo">
        <el-input v-model="submitObj.invoiceNo" type="number" placeholder="请输入发票号码" />
      </el-form-item>
      <el-form-item label="快递单号：">
        <el-input v-model="submitObj.courierNumber" placeholder="请输入快递单号" />
      </el-form-item>
      <el-form-item label="发票附件：" prop="fdfsUrl">
        <FileUpload @getFile="getFile" />
      </el-form-item>
      <el-form-item label="发票抬头：">
        {{ ruleForm.invoiceName }}
      </el-form-item>
      <el-form-item label="纳税人识别号：">
        {{ ruleForm.invoiceUnicode }}
      </el-form-item>
      <el-form-item label="开户行名称：">
        {{ ruleForm.invoiceBankName }}
      </el-form-item>
      <el-form-item label="开户行账号：">
        {{ ruleForm.invoiceBankCode }}
      </el-form-item>
      <el-form-item label="公司联系电话：">
        {{ ruleForm.invoiceTel }}
      </el-form-item>
      <el-form-item label="公司地址：">
        {{ ruleForm.recAddr }}
      </el-form-item>
      <el-form-item label="发票内容：">
        <p class="link" @click="checkInvoice">
          商品明细，发票样板查看
        </p>
      </el-form-item>
      <el-form-item label="电子邮箱：">
        {{ ruleForm.recMail }}
      </el-form-item>
      <el-form-item label="联系人姓名：">
        {{ ruleForm.recName }}
      </el-form-item>
      <el-form-item label="联系人电话：">
        {{ ruleForm.recTel }}
      </el-form-item>
      <el-form-item label="发票类型：">
        {{ ruleForm.receiveType==='00'?'普票':'专票' }}
      </el-form-item>
      <el-form-item label="发票介质：">
        {{ ruleForm.invoiceType==='00'?'电子发票':'纸质发票' }}
      </el-form-item>
      <el-form-item label="开票金额(元)：">
        {{ ruleForm.invoiceAmt }}
      </el-form-item>
      <el-form-item label="申请单号：">
        {{ ruleForm.busiNo }}
      </el-form-item>
      <el-form-item label="" />
    </el-form>
    <div class="buttonCombination">
      <el-button plain class="widen-button" size="small" @click="resetForm('ruleForm')">
        重置
      </el-button>
      <el-button class="widen-button" type="primary" size="small" @click="submitForm('ruleForm')">
        提交
      </el-button>
    </div>
    <invoiceTemplate ref="invoiceTemplate" :invoice-obj="invoiceTemplate" />
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import invoiceTemplate from './invoiceTemplate.vue'
import { invoiceIssureInvoiceDetail, agentPurchaseContractInvoiceProcess, agentPurchaseContractViewInvoiceSample } from '@/http/agentApi/financialManage'
import { formPattern } from '@/unit/matchFormRegex'
import { roundUpToInteger } from '@/unit/index'
export default {
  components: { FileUpload, invoiceTemplate },
  data() {
    return {
      ruleForm: {},
      invoiceTemplate: {
        fromObj: {}
      },
      submitObj: {
        fdfsUrl: ''
      },
      rules: {
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'change' },
          { min: 8, max: 8, message: '发票号码应为8位', trigger: 'blur' }
        ],
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { min: 10, max: 12, message: '发票代码应为10或者12位', trigger: 'blur' }
        ],
        fdfsUrl: [
          { required: true, message: '请上传', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      invoiceIssureInvoiceDetail({ busiId: this.$route.query.id }, res => {
        this.ruleForm = { ...res.data }
        this.invoiceTemplate.fromObj = { ...res.data }
      })
      agentPurchaseContractViewInvoiceSample(this.$route.query.id, res => {
        this.invoiceTemplate.taxAmount = Number(res.data.settleTotalAmount)
        this.invoiceTemplate.money = Number(res.data.settleTaxAmount)
        this.invoiceTemplate.invoiceAmt = roundUpToInteger(this.invoiceTemplate.taxAmount + this.invoiceTemplate.money)
        this.invoiceTemplate.capitalMoney = this.digitUppercase(this.invoiceTemplate.invoiceAmt)
        this.invoiceTemplate.invoiceContents = [...res.data.viewInvoiceSampleList] || []
      })
    }
  },
  methods: {
    // 点击查看发票
    checkInvoice() {
      this.$refs.invoiceTemplate.visible = true
    },
    getFile(obj) {
      this.submitObj.fdfsName = obj.fileName
      this.submitObj.fdfsUrl = obj.fileUrl
      this.$refs.ruleForm.validateField('fdfsUrl')
    },
    // 金额小写换成大写
    digitUppercase(price) {
      const fraction = ['角', '分']
      const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟']
      ]
      let num = Math.abs(price)
      let s = ''
      fraction.forEach((item, index) => {
        s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '')
      })
      s = s || '整'
      num = Math.floor(num)
      for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = ''
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
          p = digit[num % 10] + unit[1][j] + p
          num = Math.floor(num / 10)
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
      }

      return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
    },
    // 点击确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          agentPurchaseContractInvoiceProcess(this.submitObj, () => {
            this.$message.success('成功')
            this.$router.push({
              path: '/financialManage/agentBillingManage'
            })
          })
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.submitObj.courierNumber = ''
      this.submitObj.fdfsName = ''
      this.submitObj.fdfsUrl = ''
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">
.makeOutInvoice {
  .el-form {

    .flex {
      display: flex;

      a {
        display: block;
        text-align: right;
        width: 140px;
        color: $selectColor;
        text-decoration: underline;
      }
    }
  }

  .page-form {
    // padding: 32px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // background-color: #fff;

    .el-form-item {
      .link{
        color: red;
        text-decoration: underline;
        cursor: pointer;
      }
      // .el-form-item__content{
      //   // width: calc(100% - 120px);
      // }
    }
    .lastOne{
      width: 96%;
    }
  }
  .buttonCombination{
    text-align: center;
    background-color: #fff;
    padding-bottom: 32px;
  }

  .page-form:after {
    content: "";
    // width: 32%;
  }

  /deep/ .el-form-item__label {
    padding-left: 0;
  }

  .page-form .spread-over {
    width: 100%;
  }

}
</style>
